import React from 'react'
import { Widget } from 'react-typeform-embed'

import Layout from '../components/Layout'

const FormPage = (props) => {
    return <Widget id={'cYvKkY'} height={800} />
}

const Form = () => (
    <Layout>
        <FormPage />
    </Layout>
)

export default Form
